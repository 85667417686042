<template>
  <div class="guide-main">
    <div class="fmbj" :style="`height:${innerHeight}px;background-image:url('${
            (loginBg.length > 0 &&loginBg[0])
              ? requestImgUrl(loginBg[0].img)
              : ''
          }')`"></div>
    <common-header class="red dn"></common-header>
    <div class="login-main pre">
      <div class="guide-form">
        <div class="guide-item flex centre3 mb6">
          <div class="login-icon-box">
            <i class="user-icon dib"></i>
          </div>
          <div class="fe input-box">
            <input
              type="text"
              placeholder="请输入手机号"
              class="input"
              maxlength="11"
              v-model="phone"
              @blur="fixScroll"
            />
          </div>
        </div>

        <div class="guide-item flex centre3" v-if="!codeLogin">
          <div class="login-icon-box">
            <i class="pwd-icon dib"></i>
          </div>
          <div class="fe input-box">
            <input
              :type="`${seeVisible ? 'text' : 'password'}`"
              placeholder="请输入密码"
              class="input"
              v-model="pwd"
              @blur="fixScroll"
            />
          </div>
          <div class="btn-see" @click="seeClick">
            <i :class="`${seeVisible ? 'see' : 'nosee'}`"></i>
          </div>
        </div>

        <div class="guide-item flex centre3" v-else>
          <div class="login-icon-box">
            <i class="pwd-icon dib"></i>
          </div>
          <div class="fe input-box">
            <input
              placeholder="请输入验证码"
              class="input"
              v-model="pwd"
              @blur="fixScroll"
            />
          </div>
          <div class="get-code" @click="send" :class="`${verifyClass}`">{{verifyText}}</div>
        </div>

        <a href="javascript:" class="guide-large-btn" @click="login"
          >登录</a
        >

        <div class="fix guide-text">
          <div class="fl">
            没有账号？
            <router-link to="/guide/register" class="orange"
              >注册账号</router-link
            >
          </div>
          <router-link to="/guide/forget" class="fr">忘记密码?</router-link>
        </div>
        
        <!-- 微信登录 start -->
        <div class="quick-login">
          <div class="item wx" v-if="isWeiXin() && !noAuthConfig()" @click="wxLogin">
            <div class="icon-weixin-icon icon-s"></div>
            <p>微信登录</p>
          </div>
          <div class="item" @click="codeLogin = !codeLogin">
            <div :class="codeLogin ? 'icon-password-icon' : 'icon-yzm-icon'" class="icon-s"></div>
            <p>{{codeLogin ? '账号登录' : '验证码登录'}}</p>
          </div>
        </div>
        <!-- 微信登录 end -->

        <!-- <div class="login-method">
          <div class="line-text pre">
            <div class="centre2 box fix tc">
              <router-link to="/" class="enter">
                <span class="login-enter-icon"></span>
                <div class="txt">进入首页</div>
              </router-link>

              <a
                href="javascript:"
                class="enter"
                v-if="isWeiXin() && !noAuthConfig()"
                @click="wxLogin"
              >
                <span class="login-weixin2-icon"></span>
                <div class="txt">微信登录</div>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { isPhone, fixScroll } from "@/utils/utils";
import { getPreKey } from "@/utils/codeVerify";
import {
  setCookies,
  isWeiXin,
  requestImgUrl,
  authFun,
  noAuthConfig
} from "@/utils/common";
let timer = 60;
let inter;
export default {
  name: "Login",
  data() {
    this.requestImgUrl = url => requestImgUrl(url);
    this.isWeiXin = () => isWeiXin();
    this.noAuthConfig = () => noAuthConfig();
    this.fixScroll = () => fixScroll();

    return {
      da: "",
      phone: "",
      pwd: "",
      url: "",
      seeVisible: false,
      codeLogin: false,
      verifyClass: "",
      verifyText: "获取验证码",
      innerHeight: 0,
    };
  },
  computed: {
    ...mapState(["loginBg"])
  },
  mounted() {
    const q = this.$route.query;
    if (q.url) {
      this.url = decodeURIComponent(q.url);
    }
    this.innerHeight = window.innerHeight;
  },
  methods: {
    // 获取验证码 start
    send() {
      if (this.verifyClass === "disabled") {
        return;
      }
      if (this.phone === "") {
        this.$toast("手机号不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }

      this.sendTimer();
      inter = setInterval(this.sendTimer, 1000);

      getPreKey(
        {
          phone: this.phone,
          type: 0
        },
        v => {
          console.log(v);
          this.verifyCode = v.verifyCode || "";
        },
        () => {
          this.clearTimer();
        }
      );
    },
    clearTimer() {
      timer = 60;
      this.verifyClass = "";
      this.verifyText = "获取验证码";
      clearInterval(inter);
    },
    sendTimer() {
      timer--;
      if (timer < 1) {
        this.verifyClass = "";
        this.verifyText = "获取验证码";
        clearInterval(inter);
      } else {
        this.verifyClass = "disabled";
        this.verifyText = timer + "s";
      }
    },
    // 获取验证码 end
    ...mapActions("guide", ["userLogin"]),
    // 微信登录
    wxLogin() {
      const u = window.location.origin;
      this.$Cookies.remove("user");
      authFun(u);
    },
    // 密码可见不可见
    seeClick() {
      if (this.seeVisible) this.seeVisible = false;
      else this.seeVisible = true;
    },
    // 登录
    login() {
      if (this.phone === "") {
        this.$toast("手机号码不能为空");
        return;
      }
      if (!isPhone(this.phone)) {
        this.$toast("手机号码格式不正确");
        return;
      }
      if (this.pwd === "") {
        this.$toast("密码不能为空");
        return;
      }
      
      let da = {
        account: this.phone,
        pwd: this.codeLogin ? '' : this.pwd,
        verifyCode: this.codeLogin ? this.pwd : '',
        uid: localStorage.getItem('uid'),
      };

      this.da = da;
      this.userLogin(da).then(res => {
        const v = res.data;

        this.$toast("登录成功");
        // 设置cook
        setCookies(v);

        const _this = this;
        setTimeout(() => {
          if (_this.url !== "") {
            // _this.$router.go(-1);
            _this.$router.replace(_this.url);
          } else {
            _this.$router.push("/");
          }
        }, 2000);
      });
    }
  }
};
</script>
<style lang="less" scoped>
.quick-login{
  .item{
    width: 1.6rem;
  }
  .wx{
    position: relative;
    &::after{
      position: absolute;
      bottom: .04rem;
      right: -.25rem;
      width: 1px;
      height: .23rem;
      background: #ededed;
      content: "";
    }
  }
}
.fmbj{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.login-bg {
  top: 0;
  left: 0;
  z-index: 1;
}
.logo {
  width: 5rem;
  height: 2.4rem;
  z-index: 1;
  top: 1.2rem;
  left: 50%;
  display: block;
  position: absolute;
  margin-left: -2.5rem;
}

.btn-see {
  width: 0.6rem;
  text-align: right;
  height: 0.6rem;
  line-height: 0.6rem;
}

.nosee {
  width: 0.32rem;
  height: 0.25rem;
  display: inline-block;
  background: url("../../assets/images/img/pwd-nosee-icon.png") no-repeat
    center center;
  background-size: 100% auto;
}

.see {
  width: 0.32rem;
  height: 0.25rem;
  display: inline-block;
  background: url("../../assets/images/img/pwd-see-icon.png") no-repeat center
    center;
  background-size: 100% auto;
}

.line-text {
  .box {
    height: 1.2rem;
    width: 4rem;
  }
}
.enter {
  // position: absolute;
  // top: -0.36rem;
  // left: 50%;
  // margin-left: -0.6rem;
  padding-top: 0.2rem;
  display: inline-block;
  margin: 0 0.3rem;
}
.login-enter-icon {
  display: inline-block;
  width: 1.2rem;
  height: 0.74rem;
  background: url("../../assets/images/img/login-enter-icon.png") no-repeat
    center center #fff;
  background-size: 0.74rem auto;
}


.login-weixin2-icon {
  display: inline-block;
  width: 1.2rem;
  height: 0.74rem;
  background: url("../../assets/images/img/login-weixin2-icon.png") no-repeat
    center center #fff;
  background-size: 0.74rem auto;
}

.login-method {
  padding-bottom: 1.2rem;
}
.login-method .txt {
  color: #9d9d9d;
  font-size: 0.24rem;
  text-align: center;
}
</style>
